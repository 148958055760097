/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

 
 
//Para usar los colores de material angular
@use '@angular/material' as mat;
@use './theme/angular_material_theme.scss' as theme;


/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.custom-popover {
    --background: transparent; /* Hacer el fondo del popover transparente */
    --box-shadow: none; /* Eliminar la sombra predeterminada */
  }

.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.pointer-cursor {
  cursor: pointer;
}

html, body {


  //Estilos para barra de scroll
  ::-webkit-scrollbar {
    width: 10px; // Un poco más delgado 
    height: 10px; // Ancho del scroll horizontal 
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05); // Fondo del track suave y claro 
    border-radius: 10px; // Bordes redondeados 
  }

  // Color y estilo del thumb (el control que se mueve) 
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); // Thumb oscuro pero translúcido 
    border-radius: 10px; // Bordes redondeados 
    transition: background 0.3s ease; // Transición suave en hover 
    
    // Un color más oscuro al pasar el mouse 
    &:hover {
      background: rgba(0, 0, 0, 0.4); 
      width: 10px; // Ancho del scroll vertical
      height: 10px; // Ancho del scroll horizontal
    }
  }
}

// =========================================
// Aplicación del tema de material angular
// =========================================
@include mat.core();
@include mat.core-theme(theme.$my-theme);
@include mat.all-component-themes(theme.$my-theme);
@include mat.strong-focus-indicators-theme(theme.$my-theme); //Para que los botones tengan un borde al enfocarlos
//Para poder usar modales se usa esta importacion del CDK de overlay de MAT
@import '@angular/cdk/overlay-prebuilt.css';
