// ==========================
// Importaciones
// ==========================
@use '@angular/material' as mat;


// ==========================
// Definicion de paletas
// ==========================
//Las paletas se definen tomando diferentes tonos (50-950 del mas claro al mas oscuro) siendo 500 el tono principal 
//Para los colores permitidos por angular (primary-accent-warn) se definen paletas con colores similares al color base 500
$primary-palette: mat.define-palette((
  50: #effcfc,
  100: #d7f5f6,
  200: #b4ebed,
  300: #80dce0,
  400: #54ccd4,
  500: #37C0C8, //Base Color YoPresto
  600: #2ca3ac,
  700: #246f7a,
  800: #042855,
  900: #04244d,
  950: #031e40,
  //Probar como funcionan estos 4
  A100: #b4ebed,
  A200: #80dce0,
  A400: #54ccd4,
  A700: #246f7a,
  contrast: (
    50: #000000,
    500: #ffffff,
    950: #ffffff,
  )
));
//para el color accent DEFINIR CUAL SERIA EL COLOR DE ACENTO Ó SECUNDARIO
$secondary-palette: mat.define-palette((
  50:  #ebf9ff,
  100: #d6f2ff,
  200: #b3e7ff,
  300: #8adaff, 
  400: #66cfff,
  500: #3dc2ff, 
  600: #00aeff,
  700: #0081bd,
  800: #005780,
  900: #002a3d,
  950: #00151f,
  contrast: (
    50: #000000,
    500: #ffffff
  ) 
));
//Para el color de warn
$warn-palette: mat.define-palette(mat.$red-palette);

//Para colores adicionales a los 3 de material angular
$success-palette: mat.define-palette(mat.$green-palette);
$info-palette: mat.define-palette(mat.$blue-palette); 
$accent-palette: mat.define-palette(mat.$pink-palette);
$neutral-palette: mat.define-palette(mat.$grey-palette);
$alert-palette: mat.define-palette(mat.$yellow-palette);


/* se accedera manualmente a ellos asi desde archivos de estilos:
@use 'angular-theme' as material;
background-color: map-get(material.$error-palette, 700);
color: map-get(map-get(material.$primary-palette, contrast), 500);
*/


// ==========================
// Configuración de tipografía
// ========================== 
 //Actualmente no aplicado. No se ha logrado implementar
$my-typography: mat.define-typography-config((   
  fontFamily: "Roboto, 'Helvetica Neue', sans-serif", 
  headline1: mat.define-typography-level(3rem, 1.5, 300), 
  body1: mat.define-typography-level(1rem, 1.5, 400), 
  button: mat.define-typography-level(0.875rem, 1.25, 500) 
));



// ==========================
// Definición del tema
// ==========================
$my-theme: mat.define-light-theme((
  color: (
    primary: $primary-palette,
    accent: $secondary-palette,
    warn: $warn-palette,
  ),
  typography: mat.define-typography-config()
));


// ==========================
// Estilos globales para componentes material anglar
// ==========================
/*Para que el icono se ajuste automaticamente si cambia el tamaño de la letra*/ 
.mat-icon {
  height: auto !important;
  width: auto !important;
}
/*
.mat-divider {
  border: 1px solid #a29d9d !important; 
}*/

.mat-mdc-raised {
  color: #ffff;
}

mat-form-field {
  width: 100%;
}      

.mdc-text-field--outlined {
  --mdc-shape-small: 28px !important;
}

//Muestra boton para los campos de seleccion de fecha, mes, semana, hora, etc...
input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

// ==========================
// Definimos variables CSS para acceder mas facil a los colores
// ==========================
:root {
  --primary-color: #{mat.get-color-from-palette($primary-palette)};
  //El color secundario es el mismo que se definio para angular material maneja como "accent"
  --secondary-color: #{mat.get-color-from-palette($secondary-palette)};
  --tertiary-color: #{mat.get-color-from-palette($primary-palette, 800)};
  --success-color: #{mat.get-color-from-palette($success-palette)};
  --info-color: #{mat.get-color-from-palette($info-palette)};
  --neutral-color: #{mat.get-color-from-palette($neutral-palette)};
  --alert-color: #{mat.get-color-from-palette($alert-palette)};
  --warn-color: #{mat.get-color-from-palette($warn-palette)};
  --accent-color: #{mat.get-color-from-palette($accent-palette)};
  //Color primario suave para los bordes
  --primary-border: #{mat.get-color-from-palette($primary-palette, 200)};
}

